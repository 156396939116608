import React, { PropsWithChildren } from 'react';
import { ConfigProvider } from 'antd';

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#49b873',
          fontFamily: 'Gilroy',
        },
        components: {
          Table: {
            rowSelectedHoverBg: '#e9f7ec',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeProvider;
