import React, { useRef, useState } from 'react';
import { Form, Input, notification, Table, TablePaginationConfig } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  Crud,
  CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { BotSettingsForm, type FormHandlers } from '@root/feature/forms';
import { toDataSource } from '@root/lib';
import {
  useDeleteBotSettingsMutation,
  useGetBotSettingsQuery,
  useUpdateBotSettingsMutation,
} from '@root/store/api/bot-settings';
import type { BotSettings, BotSettingsResponseItem } from '@root/store/types';

const BotSettingsLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<number | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetBotSettingsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const [deleteSettings] = useDeleteBotSettingsMutation();
  const [update, { isLoading: isUpdating }] = useUpdateBotSettingsMutation();
  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  const handleEditRow = (row: BotSettings) => () => {
    form.setFieldsValue({ ...row });
    setEditableRowId(row.id);
  };
  const handleDeleteRow = (row: BotSettings) => () => {
    deleteSettings(row.id).catch(() => {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось удалить запись'),
      });
    });
  };
  const isEditing = (id: number) => id === editableRowId;
  const columns: ColumnType<BotSettingsResponseItem>[] = [
    {
      title: t('Аккаунт'),
      dataIndex: 'account',
      width: '20%',
      render: row => `${row.login} (${row.phone})`,
    },
    {
      title: t('Прокси'),
      dataIndex: 'account',
      width: '20%',
      // render: row => `${row.host}:${row.port}`,
      render: record => {
        const { twoFactorBridge } = record;
        return twoFactorBridge
          ? `${twoFactorBridge.host}:${twoFactorBridge.port}`
          : '';
      },
    },
    {
      title: t('Stream key'),
      dataIndex: 'stream_key',
      width: '20%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'stream_key',
        title: t('Stream key'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Браузер'),
      dataIndex: 'browser',
      width: '20%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'browser',
        title: t('Браузер'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Public ID'),
      dataIndex: 'public_id',
      width: '20%',
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={editableRowId !== undefined && editableRowId !== row.id}
            onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const handleSaveRow = async () => {
    try {
      const row = (await form.validateFields()) as BotSettings;
      await update({ ...row, id: editableRowId as number });
      setEditableRowId(undefined);
    } catch (e) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось обновить запись'),
      });
    }
  };

  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };

  const rows = toDataSource<BotSettingsResponseItem>(data?.data || []);
  return (
    <>
      {contextHolder}
      <Crud
        title={t('Боты')}
        drawerTitle={t('Добавить бота')}
        ref={crudRef}
        drawerContent={
          <BotSettingsForm ref={formRef} onSubmit={handleSubmit} />
        }
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<BotSettingsResponseItem>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default BotSettingsLayout;
