import React, { PropsWithChildren } from 'react';
import { IntlProvider, ReduxProvider, ThemeProvider } from '@root/providers';

const RootProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <IntlProvider>
    <ReduxProvider>
      <ThemeProvider>{children}</ThemeProvider>
    </ReduxProvider>
  </IntlProvider>
);

export default RootProvider;
