import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  CustomerResponse,
  CustomerCreate,
  Pagination,
  PostResponse,
  PaginationResponse,
  CustomerDetails,
  ApiResponse,
} from '@root/store/types';

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery,
  tagTypes: ['Customer'],
  endpoints: builder => ({
    getCustomers: builder.query<
      PaginationResponse<CustomerResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/customer?page=${page}&limit=${limit}`,
      providesTags: ['Customer'],
    }),
    getCustomer: builder.query<ApiResponse<CustomerDetails>, number>({
      query: id => `/customer/${id}`,
      providesTags: ['Customer'],
    }),
    addCustomer: builder.mutation<PostResponse, Omit<CustomerCreate, 'id'>>({
      query: body => ({
        url: 'customer',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Customer'],
    }),
    updateCustomer: builder.mutation<PostResponse, CustomerCreate>({
      query: body => ({
        url: `customer/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Customer'],
    }),
    deleteCustomer: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `customer/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Customer'],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useGetCustomerQuery,
} = customerApi;
