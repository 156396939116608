import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import { ApiTokenResponse } from '@root/store/types';

export const tokenApi = createApi({
  reducerPath: 'apiTokenApi',
  baseQuery,
  tagTypes: ['ApiToken'],
  endpoints: builder => ({
    getApiToken: builder.query<ApiTokenResponse, void>({
      query: () => '/api-token',
      providesTags: ['ApiToken'],
    }),
    generateApiToken: builder.mutation<ApiTokenResponse, void>({
      query: () => ({
        url: '/api-token',
        method: 'POST',
      }),
      invalidatesTags: ['ApiToken'],
    }),
  }),
});

export const { useGetApiTokenQuery, useGenerateApiTokenMutation } = tokenApi;
