import React, { useState } from 'react';
import { Card, Table, TablePaginationConfig } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TableActions } from '@root/components';
import { useErrorNotification } from '@root/hooks/useErrorNotification';
import { toDataSource } from '@root/lib';
import {
  useDeleteTransactionMutation,
  useGetTransactionsQuery,
} from '@root/store/api/transaction';
import type { Transaction } from '@root/store/types';
import type { FinTask } from '@root/store/types/fin-task';

const TransactionsLayout: React.FC = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const {
    data,
    isLoading,
    isError: isDataError,
  } = useGetTransactionsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });

  const [deleteTransaction, { isError: isDeleteError }] =
    useDeleteTransactionMutation();

  const isError = isDeleteError || isDataError;

  useErrorNotification(isError, t('Что-то пошло не так'));

  const handleDeleteRow = (row: FinTask) => () => {
    deleteTransaction(row.id as number);
  };

  const columns: ColumnType<Transaction>[] = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    // {
    //   title: t('Тип'),
    //   dataIndex: 'transaction_code',
    //   key: 'transaction_code',
    // },
    {
      title: t('Аккаунт'),
      dataIndex: 'account',
      key: 'account',
      render: value => value?.login,
    },
    {
      title: t('Сумма'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('Направление'),
      dataIndex: 'debit',
      key: 'debit',
      render: value => (!value ? t('Приход') : t('Расход')),
    },
    {
      title: t('Время транзакции'),
      dataIndex: 'time_received',
      key: 'time_received',
      render: value => dayjs(value).format('DD.MM.YYYY - hh:mm:ss'),
    },
    {
      title: t('Сообщение'),
      dataIndex: 'message',
      key: 'message',
    },
    // {
    //   title: t('Комментарий'),
    //   dataIndex: 'comment',
    //   key: 'comment',
    // },
    {
      title: t('Создано'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: value => dayjs(value).format('DD.MM.YYYY - hh:mm:ss'),
    },
    {
      title: t('Обновлено'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: value => dayjs(value).format('DD.MM.YYYY - hh:mm:ss'),
    },
    {
      render: (row: FinTask) => (
        <TableActions onDelete={handleDeleteRow(row)} />
      ),
    },
  ];
  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };
  const rows = toDataSource<Transaction>(data?.data || []);
  return (
    <Card title={t('Транзакции')}>
      <Table<Transaction>
        columns={columns}
        dataSource={rows}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={{
          ...pagination,
          total: data?.total || 1,
        }}
      />
    </Card>
  );
};

export default TransactionsLayout;
