import React, { useRef, useState } from 'react';
import { Form, Input, notification, Table, TablePaginationConfig } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  Crud,
  CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { CompanyForm, FormHandlers } from '@root/feature/forms';
import { toDataSource } from '@root/lib';
import {
  useDeleteCompanyMutation,
  useGetCompaniesQuery,
  useUpdateCompanyMutation,
} from '@root/store/api/company';
import type { Company } from '@root/store/types';

const CompanyLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<number | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetCompaniesQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const [deleteCompany] = useDeleteCompanyMutation();
  const [update, { isLoading: isUpdating }] = useUpdateCompanyMutation();
  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  const handleEditRow = (row: Company) => () => {
    form.setFieldsValue({ ...row });
    setEditableRowId(row.id);
  };
  const handleDeleteRow = (row: Company) => () => {
    deleteCompany(row.id).catch(() => {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось удалить запись'),
      });
    });
  };
  const isEditing = (id: number) => id === editableRowId;
  const columns: ColumnType<Company>[] = [
    {
      title: t('Название'),
      dataIndex: 'name',
      width: '20%',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      onCell: record => ({
        record: record,
        editing: isEditing(record.id),
        isRequired: true,
        dataIndex: 'name',
        title: t('Название'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Информация'),
      dataIndex: 'description',
      width: '40%',
      render: (description: string) =>
        (description || '').split('\n').map((line, key) => (
          <React.Fragment key={key}>
            {line}
            <br />
          </React.Fragment>
        )) as React.JSX.Element[],
      onCell: record => ({
        record: record,
        editing: isEditing(record.id),
        isRequired: true,
        dataIndex: 'description',
        title: t('Информация'),
        inputNode: (<Input.TextArea rows={3} />) as React.JSX.Element,
      }),
    },
    {
      title: t('Public ID'),
      dataIndex: 'public_id',
      width: '30%',
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={editableRowId !== undefined && editableRowId !== row.id}
            onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const handleSaveRow = async () => {
    try {
      const row = (await form.validateFields()) as Company;
      await update({ ...row, id: editableRowId as number });
      setEditableRowId(undefined);
    } catch (e) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось обновить запись'),
      });
    }
  };
  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };
  const rows = toDataSource<Company>(data?.data || []);
  return (
    <>
      {contextHolder}
      <Crud
        title={t('Компании')}
        drawerTitle={t('Добавить компанию')}
        ref={crudRef}
        drawerContent={<CompanyForm ref={formRef} onSubmit={handleSubmit} />}
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<Company>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default CompanyLayout;
