import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import { useGetAccountQuery } from '@root/store/api/account';
import { useAddCardMutation } from '@root/store/api/card';
import type { CardCreate } from '@root/store/types';
import styles from './CardForm.module.scss';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const CardForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addCard, { isLoading }] = useAddCardMutation();
    const { data: aData, isLoading: isAccountLoading } = useGetAccountQuery({
      page: 1,
      limit: 50,
    });
    const [form] = Form.useForm();
    const handleSubmit = (values: CardCreate) => {
      const updatedValues: CardCreate = {
        ...values,
      };
      addCard(updatedValues as CardCreate)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
        });
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    const accountData = aData?.data || [];
    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          label={t('Карта')}
          name="number"
          rules={[{ required: true, min: 16, max: 16 }]}
        >
          <Input />
        </Form.Item>
        <div className={styles.cardDataWrapper}>
          <Form.Item
            label={t('Месяц')}
            name="exp_month"
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label={t('Год')}
            name="exp_year"
            rules={[{ required: true, message: '' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label={t('CVV')}
            name="cvv"
            rules={[{ required: true, max: 3, message: '' }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          label={t('Аккаунт')}
          name="account_id"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Укажите аккаунт')}
            loading={isAccountLoading}
            filterOption={(input, option) => {
              return (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {accountData.map((account, key) => (
              <Select.Option key={key} value={account.id}>
                {account.login} ({account.phone})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default CardForm;
