import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  BankCreate,
  BankResponse,
  Pagination,
  PostResponse,
} from '@root/store/types';
import { PaginationResponse } from '@root/store/types';

export const bankApi = createApi({
  reducerPath: 'bankApi',
  baseQuery,
  tagTypes: ['Bank'],
  endpoints: builder => ({
    getBanks: builder.query<
      PaginationResponse<BankResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) => `/bank?page=${page}&limit=${limit}`,
      providesTags: ['Bank'],
    }),
    addBank: builder.mutation<PostResponse, BankCreate>({
      query: body => ({
        url: 'bank',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Bank'],
    }),
    updateBank: builder.mutation<PostResponse, BankCreate & { id: number }>({
      query: body => ({
        url: `bank/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Bank'],
    }),
    deleteBank: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `bank/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Bank'],
    }),
  }),
});

export const {
  useGetBanksQuery,
  useAddBankMutation,
  useDeleteBankMutation,
  useUpdateBankMutation,
} = bankApi;
