import React, { useRef, useState } from 'react';
import {
  Form,
  notification,
  Table,
  Space,
  type TablePaginationConfig,
  Tag,
  Select,
} from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  Crud,
  CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { type FormHandlers, RoleForm } from '@root/feature/forms';
import { toDataSource } from '@root/lib';
import { useGetPermissionsQuery } from '@root/store/api/permission';
import {
  useDeleteRoleMutation,
  useGetRoleQuery,
  useUpdateRoleMutation,
} from '@root/store/api/role';
import type { Permission, Role } from '@root/store/types';

const RoleLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<string | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetRoleQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const { data: permissionsData, isLoading: isPermissionsLoading } =
    useGetPermissionsQuery({
      page: 1,
      limit: 50,
    });
  const [deleteRole] = useDeleteRoleMutation();
  const [update, { isLoading: isUpdating }] = useUpdateRoleMutation();
  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  const handleEditRow = (row: Role & { permissions: Permission[] }) => () => {
    form.setFieldsValue({
      ...row,
      permission_ids: row.permissions.map(({ permission_id }) => permission_id),
    });
    setEditableRowId(row.role_id);
  };

  const handleDeleteRow = (row: Role) => () => {
    deleteRole(row.role_id).catch(() => {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось удалить запись'),
      });
    });
  };
  const isEditing = (id: string) => id === editableRowId;
  const columns: ColumnType<Role & { permissions: Permission[] }>[] = [
    {
      title: t('Название'),
      dataIndex: 'role_id',
      width: '40%',
    },
    {
      title: t('Доступы'),
      dataIndex: 'permissions',
      width: '50%',
      render: (record: Permission[]) => (
        <Space size={[0, 8]} wrap>
          {record.map((permission, key) => (
            <Tag key={key} color="green">
              {permission.permission_id}
            </Tag>
          ))}
        </Space>
      ),
      onCell: record => ({
        record,
        editing: isEditing(record.role_id),
        isRequired: true,
        dataIndex: 'permission_ids',
        itemType: 'array',
        inputNode: (
          <Select
            mode="multiple"
            placeholder={t('Добавьте доступы к роли')}
            loading={isPermissionsLoading}
            filterOption={(input, option) =>
              (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {(permissionsData?.data || []).map((permission, key) => (
              <Select.Option key={key} value={permission.permission_id}>
                {permission.permission_id}
              </Select.Option>
            ))}
          </Select>
        ) as React.JSX.Element,
      }),
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={
              editableRowId !== undefined &&
              editableRowId !== row.id &&
              !row.read_only
            }
            onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const handleSaveRow = async () => {
    try {
      const row = (await form.validateFields()) as Role & {
        permission_ids: string[];
      };
      await update({
        ...row,
        role_id: editableRowId as string,
      });
      setEditableRowId(undefined);
    } catch (e) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось обновить запись'),
      });
    }
  };
  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };

  const rows = toDataSource<Role & { permissions: Permission[] }>(
    data?.data || [],
  );
  return (
    <>
      {contextHolder}
      <Crud
        title={t('Роли доступа')}
        drawerTitle={t('Добавить роль')}
        ref={crudRef}
        drawerContent={<RoleForm ref={formRef} onSubmit={handleSubmit} />}
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<Role & { permissions: Permission[] }>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default RoleLayout;
