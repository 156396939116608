import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Button, Form, type FormInstance, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import {
  AccountsForm,
  CardsForm,
  GeneralForm,
  PhoneTransferForm,
} from '@root/feature/forms/FinTaskForm/forms';
import { useErrorNotification } from '@root/hooks/useErrorNotification';
import { filterOption } from '@root/lib/helpers';
import { useGetAccountQuery } from '@root/store/api/account';
import { useCreateFinTaskMutation } from '@root/store/api/fin-task';
import { useGetFinTaskTypesQuery } from '@root/store/api/resources';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

function getActiveForm(taskTypeCode: string, form?: FormInstance<any>) {
  switch (taskTypeCode) {
    case 'GENERAL':
      return <GeneralForm />;
    case 'CARDS':
      return <CardsForm />;
    case 'ACCOUNTS':
      return <AccountsForm form={form} />;
    case 'PHONE_TRANSFER':
      return <PhoneTransferForm form={form} />;
    default:
      return null;
  }
}

const FinTaskForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const [form] = Form.useForm();
    const { data: tData, isLoading: isTypesLoading } =
      useGetFinTaskTypesQuery();
    const { data: aData, isLoading: isAccountLoading } = useGetAccountQuery({
      page: 1,
      limit: 500,
    });
    const [taskTypeId, setTaskTypeId] = React.useState<number | null>(null);
    const [createTask, { isLoading, isError: isCreateError }] =
      useCreateFinTaskMutation();
    const { t } = useTranslation();

    const isError = isCreateError;

    useImperativeHandle(ref, () => ({
      resetFields: () => {
        form.resetFields();
        setTaskTypeId(null);
      },
    }));

    useErrorNotification(isError, t('Что-то пошло не так'), () => {
      onSubmit(false);
    });

    const taskTypesData = tData?.data || [];
    const accountData = aData?.data || [];

    const taskTypeCode = taskTypesData.find(
      type => type.id === taskTypeId,
    )?.code;

    useEffect(() => {
      // Сброс значения task_data при изменении taskTypeCode
      form.setFieldsValue({ task_data: {} });
    }, [taskTypeCode, form]);

    const handleSubmit = (values: Record<string, any>) => {
      const tasksWithData = ['ACCOUNTS', 'PHONE_TRANSFER'];
      const { type_id, task_data, account_id, comment = '' } = values;
      const updatedValues = {
        type_id,
        task_data: tasksWithData.includes(taskTypeCode!) ? task_data : {},
        account_id,
        comment,
      };
      createTask(updatedValues)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
          setTaskTypeId(null);
        });
    };

    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          label={t('Тип задачи')}
          name="type_id"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Выбрать тип задачи')}
            loading={isTypesLoading}
            onChange={value => setTaskTypeId(value)}
          >
            {taskTypesData.map((type, key) => (
              <Select.Option key={key} value={type.id}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {!!taskTypeCode && (
          <Form.Item name="task_data">
            {getActiveForm(taskTypeCode, form)}
          </Form.Item>
        )}
        <Form.Item
          label={t('Назначить на аккаунт')}
          name="account_id"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Укажите аккаунт')}
            loading={isAccountLoading}
            filterOption={filterOption}
            showSearch
          >
            {accountData.map((account, key) => (
              <Select.Option key={key} value={account.id}>
                {account.login} ({account.bank?.name || ''})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('Комментарий')}
          name="comment"
          rules={[{ required: false }]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default FinTaskForm;
