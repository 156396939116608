import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  Pagination,
  PostResponse,
  PaginationResponse,
  DevicesResponse,
  DeviceCreate,
  ApiResponse,
  Device,
} from '@root/store/types';

export const deviceApi = createApi({
  reducerPath: 'deviceApi',
  baseQuery,
  tagTypes: ['Device'],
  endpoints: builder => ({
    getDevices: builder.query<
      PaginationResponse<DevicesResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/device?page=${page}&limit=${limit}`,
      providesTags: ['Device'],
    }),
    getDevice: builder.query<ApiResponse<Device>, number>({
      query: id => `/device/${id}`,
      providesTags: ['Device'],
    }),
    addDevice: builder.mutation<PostResponse, DeviceCreate>({
      query: body => ({
        url: 'device',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Device'],
    }),
    updateDevice: builder.mutation<PostResponse, DeviceCreate & { id: number }>(
      {
        query: body => ({
          url: `device/${body.id}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['Device'],
      },
    ),
    deleteDevice: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `device/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Device'],
    }),
  }),
});

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  useAddDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useLazyGetDeviceQuery,
} = deviceApi;
