import { bindActionCreators, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { accountApi } from '@root/store/api/account';
import { tokenApi } from '@root/store/api/api-token';
import { bankApi } from '@root/store/api/bank';
import { botSettingsApi } from '@root/store/api/bot-settings';
import { cardApi } from '@root/store/api/card';
import { companyApi } from '@root/store/api/company';
import { customerApi } from '@root/store/api/customer';
import { deviceApi } from '@root/store/api/device';
import { finTaskApi } from '@root/store/api/fin-task';
import { notificationApi } from '@root/store/api/notification';
import { paymentInNotificationApi } from '@root/store/api/payment-in-notification';
import { permissionApi } from '@root/store/api/permission';
import { proxyApi } from '@root/store/api/proxy';
import { resourcesApi } from '@root/store/api/resources';
import { roleApi } from '@root/store/api/role';
import { transactionApi } from '@root/store/api/transaction';
import { userApi } from '@root/store/api/user';
import appReducer, {
  APP_SLICE_KEY,
  appActions,
} from '@root/store/slices/appSlice';

export const store = configureStore({
  reducer: {
    [APP_SLICE_KEY]: appReducer,
    [cardApi.reducerPath]: cardApi.reducer,
    [bankApi.reducerPath]: bankApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [proxyApi.reducerPath]: proxyApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [deviceApi.reducerPath]: deviceApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [tokenApi.reducerPath]: tokenApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [botSettingsApi.reducerPath]: botSettingsApi.reducer,
    [resourcesApi.reducerPath]: resourcesApi.reducer,
    [finTaskApi.reducerPath]: finTaskApi.reducer,
    [paymentInNotificationApi.reducerPath]: paymentInNotificationApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      cardApi.middleware,
      bankApi.middleware,
      companyApi.middleware,
      permissionApi.middleware,
      roleApi.middleware,
      userApi.middleware,
      proxyApi.middleware,
      customerApi.middleware,
      deviceApi.middleware,
      accountApi.middleware,
      tokenApi.middleware,
      transactionApi.middleware,
      notificationApi.middleware,
      botSettingsApi.middleware,
      resourcesApi.middleware,
      finTaskApi.middleware,
      paymentInNotificationApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type RootStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

setupListeners(store.dispatch);

export function useActions() {
  const dispatch = useDispatch();
  return bindActionCreators({ ...appActions }, dispatch);
}
