import React, { useRef, useState } from 'react';
import { Form, Input, Select, Table, type TablePaginationConfig } from 'antd';
import { type ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  Crud,
  type CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { BankForm, type FormHandlers } from '@root/feature/forms';
import { useErrorNotification } from '@root/hooks/useErrorNotification';
import { toDataSource } from '@root/lib';
import countryList from '@root/mock/countryList.json';
import {
  useAddBankMutation,
  useDeleteBankMutation,
  useGetBanksQuery,
  useUpdateBankMutation,
} from '@root/store/api/bank';
import { type Bank } from '@root/store/types';

const BankLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<number | undefined>(
    undefined,
  );
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const { data, isLoading } = useGetBanksQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const [addBank, { isLoading: isAdding, isError: isAddError }] =
    useAddBankMutation();
  const [deleteBank, { isError: isDeleteError }] = useDeleteBankMutation();

  const [update, { isLoading: isUpdating, isError: isUpdateError }] =
    useUpdateBankMutation();

  useErrorNotification(isAddError, t('Не удалось создать запись'));
  useErrorNotification(isDeleteError, t('Не удалось удалить запись'));
  useErrorNotification(isUpdateError, t('Не удалось обновить запись'));

  const handleSubmit = (values: Record<string, any>) => {
    addBank(values as Omit<Bank, 'id'>).finally(() => {
      crudRef.current?.showDrawer(false);
    });
  };

  const handleEditRow = (row: Bank) => () => {
    form.setFieldsValue({ ...row });
    setEditableRowId(row.id);
  };
  const handleDeleteRow = (row: Bank) => () => {
    deleteBank(row.id);
  };

  const handleSaveRow = async () => {
    const row = (await form.validateFields()) as Bank;
    update({ ...row, id: editableRowId as number });
    setEditableRowId(undefined);
  };

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };

  const isEditing = (id: number) => id === editableRowId;
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const columns: ColumnType<Bank>[] = [
    {
      title: t('Название'),
      dataIndex: 'name',
      width: '20%',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'name',
        title: t('Название'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Сокращенно'),
      dataIndex: 'short_name',
      width: '20%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        dataIndex: 'short_name',
        title: t('Сокращенно'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Страна'),
      dataIndex: 'country',
      width: '20%',
      sorter: {
        compare: (a, b) => a.country.localeCompare(b.country),
      },
      render: country_code => {
        const country = countryList.find(item => item.code === country_code);
        return country ? country.name : country_code;
      },
      onCell: record => ({
        record,
        editing: isEditing(record.id),
        dataIndex: 'country',
        title: t('Страна'),
        inputNode: (
          <Select
            placeholder={t('Выберите страну')}
            filterOption={(input, option) =>
              (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            showSearch
          >
            {countryList.map((country, key) => (
              <Select.Option key={key} value={country.code}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        ) as React.JSX.Element,
      }),
    },
    {
      title: t('Public ID'),
      dataIndex: 'public_id',
      width: '30%',
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={editableRowId !== undefined && editableRowId !== row.id}
            onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const rows = toDataSource<Bank>(data?.data || []);
  return (
    <>
      <Crud
        title={t('Банки')}
        drawerTitle={t('Добавить банк')}
        drawerContent={
          <BankForm ref={formRef} onSubmit={handleSubmit} loading={isAdding} />
        }
        ref={crudRef}
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<Bank>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default BankLayout;
