import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, Select } from 'antd';
import type { Rule, RuleObject } from 'antd/lib/form';
import { isValidNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import { useGetCustomersQuery } from '@root/store/api/customer';
import { useAddDeviceMutation } from '@root/store/api/device';
import type { DeviceCreate } from '@root/store/types';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const DeviceForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addDevice, { isLoading }] = useAddDeviceMutation();
    const { data: cData, isLoading: isCustomerLoading } = useGetCustomersQuery({
      page: 1,
      limit: 50,
    });
    const [form] = Form.useForm();
    const handleSubmit = (values: DeviceCreate) => {
      const updatedValues: DeviceCreate = {
        ...values,
      };
      addDevice(updatedValues as DeviceCreate)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
        });
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    const customerData = cData?.data || [];

    const phoneNumberValidator: Rule = {
      validator: (_, value: string) => {
        if (!value || isValidNumber(value, 'RU')) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t('Некорректный номер телефона')));
      },
    };

    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          label={t('Название')}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Описание')}
          name="info"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Secret')}
          name="secret"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Номер телефона устройства')}
          name="phone_number"
          rules={[{ required: true }, phoneNumberValidator]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Абонент')}
          name="customer_id"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Выберите абонента')}
            loading={isCustomerLoading}
            showSearch
            filterOption={(input, option) => {
              return (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {customerData.map((customer, key) => (
              <Select.Option key={key} value={customer.id}>
                {customer.last_name} {customer.first_name}{' '}
                {customer.middle_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default DeviceForm;
