import React from 'react';
import { Card, Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApiKeyTab } from '@root/layout/ProfileLayout/parts';
import { useGetMeQuery } from '@root/store/api/user';
import styles from './ProfileLayout.module.scss';

const ProfileLayout: React.FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetMeQuery();
  const tabItems: TabsProps['items'] = [
    {
      key: 'profile',
      label: t('API-ключ'),
      children: <ApiKeyTab />,
    },
  ];
  return (
    <div className={styles.container}>
      <Card
        title={`${t('Profile')}: ${data?.name || ''}`}
        style={{ width: 966 }}
        loading={isLoading}
      >
        <Tabs items={tabItems} />
      </Card>
    </div>
  );
};

export default ProfileLayout;
