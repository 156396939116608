import React, { useEffect, useState } from 'react';
import { FormInstance, SelectProps, Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';

type Period = 'day' | 'week' | 'month' | 'quarter' | 'year' | 'all';

interface FormData {
  period: Period | null;
}

type Props = {
  onChange?: (_data: FormData) => void;
  form?: FormInstance<any>;
};

const AccountsForm: React.FC<Props> = ({ onChange = () => {}, form }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    period: null,
  });

  const options: SelectProps['options'] = [
    { value: 'week', label: t('Неделя') },
    { value: 'month', label: t('Месяц') },
    { value: 'quarter', label: t('Квартал') },
    { value: 'year', label: t('Год') },
    { value: 'all', label: t('За всё время') },
  ];

  useEffect(() => {
    onChange(formData);
  }, [formData, onChange]);

  useEffect(
    () => () => {
      const initialData = {
        period: null,
      };
      if (form) {
        form.setFieldsValue(initialData);
      }
      setFormData(initialData);
    },
    [form],
  );

  const handleChange = (value: Period) => {
    setFormData({ ...formData, period: value });
  };

  return (
    <Form.Item
      name="period"
      label={t('Период')}
      style={{ marginBottom: 0 }}
      rules={[
        {
          required: true,
          message: t('Выберите период'),
        },
      ]}
    >
      <Select
        options={options}
        onChange={handleChange}
        placeholder={t('Выберите период')}
      />
    </Form.Item>
  );
};

export default AccountsForm;
