import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  CardCreate,
  CardResponse,
  Pagination,
  PostResponse,
} from '@root/store/types';
import type { PaginationResponse } from '@root/store/types';

export const cardApi = createApi({
  reducerPath: 'cardApi',
  baseQuery,
  tagTypes: ['Card'],
  endpoints: builder => ({
    getCard: builder.query<
      PaginationResponse<CardResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) => `/card?page=${page}&limit${limit}`,
      providesTags: ['Card'],
    }),
    addCard: builder.mutation<PostResponse, CardCreate>({
      query: body => ({
        url: 'card',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Card'],
    }),
    updateCard: builder.mutation<PostResponse, CardCreate & { id: number }>({
      query: body => ({
        url: `card/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Card'],
    }),
    deleteCard: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `card/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Card'],
    }),
  }),
});

export const {
  useGetCardQuery,
  useAddCardMutation,
  useUpdateCardMutation,
  useDeleteCardMutation,
} = cardApi;
