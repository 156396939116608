import React, { useEffect } from 'react';
import { Button, Form, Input, notification } from 'antd';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { APP_TOKEN_KEY } from '@root/constants/app';
import { useLoginMutation } from '@root/store/api/user';
import { LoginRequest } from '@root/store/types';
import styles from './SignInLayout.module.scss';

const SignInLayout: React.FC = () => {
  const [login, { isLoading, data, isError }] = useLoginMutation();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [api, contextHolder] = notification.useNotification();
  const onFinish = async (values: LoginRequest) => {
    login(values);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };
  useEffect(() => {
    if (isError) {
      api.error({
        message: t('Error'),
        description: t('Invalid credentials'),
      });
    }
  }, [api, isError, t]);

  useEffect(() => {
    if (data && data.status === 'success') {
      Cookies.set(APP_TOKEN_KEY, data.access_token);
      window.location.href = '/';
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600, minWidth: 400 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<LoginRequest>
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item<LoginRequest>
            label={t('Пароль')}
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {t('Войти')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {contextHolder}
    </div>
  );
};

export default SignInLayout;
