import React, { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import cn from 'classnames';
import { AppHeader, SideBar } from '@root/layout/AppLayout/parts';
import { appSelector, type AppSliceState } from '@root/store/slices/appSlice';
import { useTypedSelector } from '@root/store/store';
import styles from './AppLayout.module.scss';

const { Content } = Layout;

type Props = {
  authorized: boolean;
};

const AppLayout: React.FC<PropsWithChildren<Props>> = ({
  authorized,
  children,
}) => {
  const { sideBarCollapsed } = useTypedSelector<AppSliceState>(appSelector);
  return (
    <Layout>
      {authorized ? (
        <>
          <AppHeader />
          <SideBar />
          <Content className={styles.content}>
            <div
              className={cn([
                styles.innerContent,
                { [styles.innerContent_collapsed]: sideBarCollapsed },
              ])}
            >
              {children}
            </div>
          </Content>
        </>
      ) : (
        children
      )}
    </Layout>
  );
};

export default AppLayout;
