import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  Role as RoleType,
  Pagination,
  PostResponse,
  PaginationResponse,
  RoleResponse,
} from '@root/store/types';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery,
  tagTypes: ['Role'],
  endpoints: builder => ({
    getRole: builder.query<
      PaginationResponse<RoleResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) => `/role?page=${page}&limit=${limit}`,
      providesTags: ['Role'],
    }),
    addRole: builder.mutation<
      PostResponse,
      RoleType & { permission_ids: string[] }
    >({
      query: body => ({
        url: 'role',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Role'],
    }),
    updateRole: builder.mutation<
      PostResponse,
      RoleType & { permission_ids: string[] }
    >({
      query: body => ({
        url: `role/${body.role_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Role'],
    }),
    deleteRole: builder.mutation<PostResponse, string>({
      query: role_id => ({
        url: `role/${role_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Role'],
    }),
  }),
});

export const {
  useGetRoleQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleApi;
