import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  Pagination,
  PostResponse,
  PaginationResponse,
  AccountResponse,
  AccountCreate,
} from '@root/store/types';

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery,
  tagTypes: ['Account'],
  endpoints: builder => ({
    getAccount: builder.query<
      PaginationResponse<AccountResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/account?page=${page}&limit=${limit}`,
      providesTags: ['Account'],
    }),
    addAccount: builder.mutation<PostResponse, AccountCreate>({
      query: body => ({
        url: 'account',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Account'],
    }),
    updateAccount: builder.mutation<
      PostResponse,
      AccountCreate & { id: number }
    >({
      query: body => ({
        url: `account/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Account'],
    }),
    deleteAccount: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `account/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Account'],
    }),
  }),
});

export const {
  useGetAccountQuery,
  useAddAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
} = accountApi;
