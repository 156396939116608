import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  Pagination,
  PaginationResponse,
  PostResponse,
  TransactionResponse,
} from '@root/store/types';

export const transactionApi = createApi({
  reducerPath: 'transactionApi',
  baseQuery: baseQuery,
  tagTypes: ['Transaction'],
  endpoints: builder => ({
    getTransactions: builder.query<
      PaginationResponse<TransactionResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/transaction?page=${page}&limit=${limit}`,
      providesTags: ['Transaction'],
    }),
    deleteTransaction: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `/transaction/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Transaction'],
    }),
  }),
});

export const { useGetTransactionsQuery, useDeleteTransactionMutation } =
  transactionApi;
