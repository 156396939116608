import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import { useAddAccountMutation } from '@root/store/api/account';
import { useGetBanksQuery } from '@root/store/api/bank';
import { useGetCustomersQuery } from '@root/store/api/customer';
import { useGetDevicesQuery } from '@root/store/api/device';
import { useGetProxyQuery } from '@root/store/api/proxy';
import type { AccountCreate } from '@root/store/types';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const AccountForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addAccount, { isLoading }] = useAddAccountMutation();
    const { data: pData, isLoading: isProxyLoading } = useGetProxyQuery({
      page: 1,
      limit: 50,
      unlinked: true,
    });
    const { data: bData, isLoading: isBankLoading } = useGetBanksQuery({
      page: 1,
      limit: 50,
    });
    const { data: cData, isLoading: isCustomerLoading } = useGetCustomersQuery({
      page: 1,
      limit: 50,
    });
    const { data: dData, isLoading: isDeviceLoading } = useGetDevicesQuery({
      page: 1,
      limit: 50,
    });
    const [form] = Form.useForm();
    const handleSubmit = (values: AccountCreate) => {
      const updatedValues: AccountCreate = {
        ...values,
      };
      addAccount(updatedValues as AccountCreate)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
        });
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    const proxyData = pData?.data || [];
    const bankData = bData?.data || [];
    const customerData = cData?.data || [];
    const deviceData = dData?.data || [];

    return (
      <>
        <Form
          layout="vertical"
          style={{ width: '100%' }}
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            label={t('Логин')}
            name="login"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('Пароль')}
            name="password"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('Метод входа')}
            name="type_of_creds"
            rules={[{ required: true }]}
          >
            <Select placeholder={t('Укажите метод входа')}>
              <Select.Option value="login">{t('Логин')}</Select.Option>
              <Select.Option value="phone">{t('Телефон')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('Телефон')}
            name="phone"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('Абонент')}
            name="customer_id"
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t('Выберите абонента')}
              loading={isCustomerLoading}
              showSearch
              filterOption={(input, option) =>
                (String(option?.children) || '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {customerData.map((customer, key) => (
                <Select.Option key={key} value={customer.id}>
                  {customer.last_name} {customer.first_name}{' '}
                  {customer.middle_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('Банк')}
            name="bank_id"
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t('Укажите банк')}
              loading={isBankLoading}
              filterOption={(input, option) =>
                (String(option?.children) || '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {bankData.map((bank, key) => (
                <Select.Option key={key} value={bank.id}>
                  {bank.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('Устройство')}
            name="device_id"
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t('Укажите устройство')}
              loading={isDeviceLoading}
              filterOption={(input, option) =>
                (String(option?.children) || '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {deviceData.map((device, key) => (
                <Select.Option key={key} value={device.id}>
                  {device.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('Прокси')}
            name="two_factor_bridge_id"
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t('Укажите проокси')}
              loading={isProxyLoading}
              filterOption={(input, option) =>
                (String(option?.children) || '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {proxyData.map((proxy, key) => (
                <Select.Option key={key} value={proxy.id}>
                  {proxy.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {t(submitTitle || t('Добавить'))}
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  },
);

export default AccountForm;
