import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';

import type {
  Pagination,
  PaginationResponse,
  PaymentInNotificationResponse,
  PostResponse,
} from '@root/store/types';

export const paymentInNotificationApi = createApi({
  reducerPath: 'paymentInNotificationApi',
  baseQuery: baseQuery,
  tagTypes: ['PaymentInNotification'],
  endpoints: builder => ({
    getPaymentInNotifications: builder.query<
      PaginationResponse<PaymentInNotificationResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/payment-in-notification?page=${page}&limit=${limit}`,
      providesTags: ['PaymentInNotification'],
    }),
    deletePaymentInNotification: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `/payment-in-notification/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentInNotification'],
    }),
  }),
});

export const {
  useGetPaymentInNotificationsQuery,
  useDeletePaymentInNotificationMutation,
} = paymentInNotificationApi;
