import React, { PropsWithChildren } from 'react';
import { Form } from 'antd';
import './ant-styles.scss';

type Props = React.HTMLAttributes<HTMLElement> & {
  editing: boolean;
  dataIndex: string;
  isRequired?: boolean;
  itemType?: 'array' | 'email' | 'number' | 'object' | 'string' | 'url';
  inputNode: React.ReactNode;
};

const EditableCell: React.FC<PropsWithChildren<Props>> = ({
  editing,
  dataIndex,
  isRequired,
  itemType,
  inputNode,
  children,
  ...rest
}) => {
  return (
    <td {...rest}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: isRequired,
              type: itemType,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
