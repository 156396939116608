import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { RootState } from '@root/store/store';

export const APP_SLICE_KEY = 'app';

export interface AppSliceState {
  loading: boolean;
  sideBarCollapsed: boolean;
}

const initialState: AppSliceState = {
  loading: false,
  sideBarCollapsed: true,
};

const appSlice = createSlice({
  name: APP_SLICE_KEY,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.loading = action.payload.loading;
    },
    setSideBarCollapsed: (
      state,
      action: PayloadAction<{ collapsed: boolean }>,
    ) => {
      state.sideBarCollapsed = action.payload.collapsed;
    },
  },
});

const appReducer = appSlice.reducer as Reducer;
export default appReducer;

export const appSelector = (state: RootState) => state.app;

export const appActions = appSlice.actions;
