import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type { ApiResponse } from '@root/store/types';
import type { Currency, FinTaskType } from '@root/store/types/resources';

export const resourcesApi = createApi({
  reducerPath: 'resourcesApi',
  baseQuery,
  tagTypes: ['Resources'],
  endpoints: builder => ({
    getCurrencies: builder.query<ApiResponse<Currency[]>, void>({
      query: () => '/resources/currencies',
      providesTags: ['Resources'],
    }),
    getFinTaskTypes: builder.query<ApiResponse<FinTaskType[]>, void>({
      query: () => '/resources/fin-task-types',
      providesTags: ['Resources'],
    }),
  }),
});

export const { useGetCurrenciesQuery, useGetFinTaskTypesQuery } = resourcesApi;
