import React, { useState } from 'react';
import { Card, Table, TablePaginationConfig } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toDataSource } from '@root/lib';
import { useGetPaymentInNotificationsQuery } from '@root/store/api/payment-in-notification';
import type { PaymentInNotification } from '@root/store/types';

const PaymentInNotificationsLayout: React.FC = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { data, isLoading } = useGetPaymentInNotificationsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const columns: ColumnType<PaymentInNotification>[] = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('Аккаунт'),
      dataIndex: 'account',
      key: 'account',
      render: account => account?.login,
    },
    {
      title: t('Банк'),
      dataIndex: 'account',
      key: 'bank',
      render: account => account?.bank?.name,
    },
    {
      title: t('Сумма'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('Время'),
      dataIndex: 'dttm',
      key: 'dttm',
      render: dttm => moment(dttm).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: t('Текст сообщения'),
      dataIndex: 'message',
      key: 'message',
    },
  ];
  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };
  const rows = toDataSource<PaymentInNotification>(data?.data || []);
  return (
    <Card title={t('Уведомления')}>
      <Table<PaymentInNotification>
        pagination={{
          ...pagination,
          total: data?.total || 1,
        }}
        loading={isLoading}
        columns={columns}
        dataSource={rows}
        onChange={handleTableChange}
      />
    </Card>
  );
};

export default PaymentInNotificationsLayout;
