import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  Permission as PermissionType,
  Pagination,
  PostResponse,
  PaginationResponse,
  PermissionResponse,
} from '@root/store/types';

export const permissionApi = createApi({
  reducerPath: 'permissionApi',
  baseQuery,
  tagTypes: ['Permission'],
  endpoints: builder => ({
    getPermissions: builder.query<
      PaginationResponse<PermissionResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/permission?page=${page}&limit=${limit}`,
      providesTags: ['Permission'],
    }),
    addPermission: builder.mutation<PostResponse, Omit<PermissionType, 'id'>>({
      query: body => ({
        url: 'permission',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Permission'],
    }),
    updatePermission: builder.mutation<PostResponse, PermissionType>({
      query: body => ({
        url: `permission/${body.permission_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Permission'],
    }),
    deletePermission: builder.mutation<PostResponse, string>({
      query: id => ({
        url: `permission/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Permission'],
    }),
  }),
});

export const {
  useGetPermissionsQuery,
  useAddPermissionMutation,
  useUpdatePermissionMutation,
  useDeletePermissionMutation,
} = permissionApi;
