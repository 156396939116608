import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import countryList from '@root/mock/countryList.json';
import { useGetCompaniesQuery } from '@root/store/api/company';
import { useAddCustomerMutation } from '@root/store/api/customer';
import type { CustomerCreate } from '@root/store/types';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const CustomerForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addCustomer, { isLoading }] = useAddCustomerMutation();
    const { data: cData, isLoading: isCompanyLoading } = useGetCompaniesQuery({
      page: 1,
      limit: 50,
    });
    const [form] = Form.useForm();
    const companyData = cData?.data || [];
    const handleSubmit = (values: Record<string, any>) => {
      const customerCreate: Omit<CustomerCreate, 'id'> = {
        first_name: values.first_name,
        last_name: values.last_name,
        middle_name: values.middle_name,
        date_of_birth: moment(values.date_of_birth).toISOString(),
        place_of_birth: values.place_of_birth,
        phone_number: values.phone_number,
        company_id: values.company_id,
        cert: {
          doc_number: values.doc_number,
          issue_date: moment(values.issue_date).toISOString(),
          issued_by: values.issued_by,
          expire_date: moment(values.expire_date).toISOString(),
          additional_info: values.additional_info,
        },
        address: {
          country_code: values.country,
          street_address: values.street_address,
          city: values.city,
          state: values.state,
          postal_code: values.postal_code.toString(), // Переводим в строку, так как в интерфейсе это строка
        },
      };
      addCustomer(customerCreate)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
        });
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    return (
      <Form
        layout="horizontal"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
        {...layout}
        initialValues={{
          country: 'RUS',
        }}
      >
        <Form.Item
          label={t('Компания')}
          name="company_id"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Выберите компанию')}
            loading={isCompanyLoading}
            showSearch
            filterOption={(input, option) => {
              return (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {companyData.map((company, key) => (
              <Select.Option key={key} value={company.id}>
                {company.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('Фамилия')}
          name="last_name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Имя')}
          name="first_name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('Отчество')} name="middle_name" rules={[{}]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Дата рождения')}
          name="date_of_birth"
          rules={[{ required: true }]}
        >
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item
          label={t('Место рождения')}
          name="place_of_birth"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Телефон')}
          name="phone_number"
          rules={[{ required: true, type: 'string' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Серия и номер паспорта')}
          name="doc_number"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Кем выдан')}
          name="issued_by"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Дата выдачи')}
          name="issue_date"
          rules={[{ required: true }]}
        >
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item
          label={t('Дата окончания действия')}
          name="expire_date"
          rules={[{ required: true }]}
        >
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item
          label={t('Доп. информация')}
          name="additional_info"
          rules={[{}]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label={t('Страна')}
          name="country"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Выберите страну')}
            filterOption={(input, option) => {
              return (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            showSearch
          >
            {countryList.map((country, key) => (
              <Select.Option key={key} value={country.code}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('Индекс')}
          name="postal_code"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label={t('Область')}
          name="state"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('Город')} name="city" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Адрес')}
          name="street_address"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default CustomerForm;
