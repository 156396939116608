import type { DefaultOptionType } from 'rc-select/lib/Select';
import type { TwoFactorBridge } from '@root/store/types';

export function formatNumber(n: number): string {
  if (n < 1e3) return n.toString(); // меньше 1000
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'; // от 1000 до 1 миллиона
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'; // от 1 миллиона до 1 миллиарда
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'; // от 1 миллиарда до 1 триллиона
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'; // больше 1 триллиона

  return n.toString();
}

export function toCapitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toCamelCase(str: string): string {
  return str
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return toCapitalize(word);
    })
    .join('');
}

export function toSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function toKebabCase(str: string): string {
  return str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
}

export function filterOption(
  input: string,
  option: DefaultOptionType | undefined,
) {
  return (String(option?.children) || '')
    .toLowerCase()
    .includes(input.toLowerCase());
}

export function stringToIdentifier(
  s: string,
  toUpperCase: boolean = false,
): string {
  s = s.toLowerCase();
  const translitMap: { [key: string]: string } = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'yo',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'sch',
    ь: '',
    ы: 'y',
    ъ: '',
    э: 'e',
    ю: 'yu',
    я: 'ya',
  };

  let transliterated = '';
  for (const char of s) {
    if (translitMap[char]) {
      transliterated += translitMap[char];
    } else {
      transliterated += char;
    }
  }
  const result = transliterated.replace(/[^a-z0-9]+/g, '_').replace(/_+/g, '_');
  return toUpperCase ? result.toUpperCase() : result;
}

export function makeProxyString(proxy: Partial<TwoFactorBridge>): string {
  const { type, host, port, login, password } = proxy;
  const credentials = login && password ? `${login}:${password}@` : '';
  return `${type}://${credentials}${host}:${port}`;
}

export function splitIntoChunks(input: string): string {
  // Удаляем все пробелы из исходной строки, чтобы обеспечить корректное разбиение
  const sanitizedInput = input.replace(/\s+/g, '');

  // Разбиваем строку на фрагменты по 4 символа
  let chunks: string[] = [];
  for (let i = 0; i < sanitizedInput.length; i += 4) {
    chunks.push(sanitizedInput.slice(i, i + 4));
  }

  // Объединяем фрагменты, разделяя их пробелами
  return chunks.join(' ');
}
