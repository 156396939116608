import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import { useGetAccountQuery } from '@root/store/api/account';
import { useAddBotSettingsMutation } from '@root/store/api/bot-settings';
import type { BotSettingsCreate } from '@root/store/types';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const BotSettingsForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addSettings, { isLoading }] = useAddBotSettingsMutation();
    const { data: aData, isLoading: isAccountLoading } = useGetAccountQuery({
      page: 1,
      limit: 500,
    });
    const [form] = Form.useForm();
    const handleSubmit = (values: BotSettingsCreate) => {
      const updatedValues: BotSettingsCreate = {
        ...values,
      };
      addSettings(updatedValues as BotSettingsCreate)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
        });
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    const accountData = aData?.data || [];
    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
        initialValues={{
          enabled: true,
          browser: 'Chrome',
        }}
      >
        <Form.Item
          label={t('Stream key')}
          name="stream_key"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Браузер')}
          name="browser"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Разрешен')}
          name="enabled"
          rules={[{ required: true }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t('Аккаунт')}
          name="account_id"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Укажите аккаунт')}
            loading={isAccountLoading}
            showSearch
            filterOption={(input, option) =>
              (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {accountData.map((account, key) => (
              <Select.Option key={key} value={account.id}>
                {account.login} ({account.phone})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default BotSettingsForm;
