import React, { useRef, useState } from 'react';
import { Form, notification, Table, TablePaginationConfig } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Crud,
  CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { CustomerForm, type FormHandlers } from '@root/feature/forms';
import { toDataSource } from '@root/lib';
import {
  useDeleteCustomerMutation,
  useGetCustomersQuery,
  useUpdateCustomerMutation,
} from '@root/store/api/customer';
import type { Customer } from '@root/store/types';

const CustomersLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<number | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetCustomersQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const [deleteCustomer] = useDeleteCustomerMutation();
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [update, { isLoading: isUpdating }] = useUpdateCustomerMutation();
  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const handleEditRow = (row: Customer) => () => {
    form.setFieldsValue({ ...row });
    setEditableRowId(row.id);
  };
  const handleDeleteRow = (row: Customer) => () => {
    deleteCustomer(row.id).catch(() => {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось удалить запись'),
      });
    });
  };
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const isEditing = (id: number) => id === editableRowId;
  const columns: ColumnType<Customer>[] = [
    {
      title: t('ФИО'),
      dataIndex: 'first_name',
      width: '30%',
      sorter: true,
      render: (_value, row) => (
        <Link to={`/customers/${row.id}`}>
          {row.last_name} {row.first_name} {row.middle_name}
        </Link>
      ),
    },
    {
      title: t('Дата рождения'),
      dataIndex: 'date_of_birth',
      width: '20%',
      sorter: true,
      render: value => moment(value).format('DD.MM.YYYY'),
    },
    {
      title: t('Паспорт'),
      dataIndex: 'cert',
      width: '20%',
      sorter: true,
      render: value => value.doc_number,
    },
    {
      title: t('Public ID'),
      dataIndex: 'public_id',
      width: '20%',
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            // TODO: Здесь редактирование будет не инлайн, а через дравер
            onSave={() => {}}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={editableRowId !== undefined && editableRowId !== row.id}
            // onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };
  const rows = toDataSource<Customer>(data?.data || []);
  return (
    <>
      {contextHolder}
      <Crud
        title={t('Абоненты')}
        drawerTitle={t('Добавить абонента')}
        ref={crudRef}
        drawerContent={<CustomerForm ref={formRef} onSubmit={handleSubmit} />}
        onDrawerClose={formRef.current?.resetFields}
        drawerWidth={640}
      >
        <Form form={form} component={false}>
          <Table<Customer>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default CustomersLayout;
