import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { App, Button } from 'antd';
import type { ButtonProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';

export type CopyButtonProps = {
  textToCopy: string;
  size?: ButtonProps['size'];
  noNotification?: boolean;
};

const CopyButton: React.FC<CopyButtonProps> = ({
  textToCopy,
  size = 'small',
  noNotification = false,
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy);
    if (!noNotification) {
      message.success(t('Скопировано!'));
    }
  };

  return <Button size={size} icon={<CopyOutlined />} onClick={handleCopy} />;
};

export default CopyButton;
