import React, {
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useState,
} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  buttonTitle?: string;
  drawerTitle?: string;
  drawerContent?: React.ReactNode;
  onDrawerSubmit?: () => void;
  onDrawerClose?: () => void;
  drawerWidth?: number;
};

export interface CrudHandlers {
  showDrawer: (_visible: boolean) => void;
}

const Crud = forwardRef<CrudHandlers, PropsWithChildren<Props>>(
  (
    {
      title,
      buttonTitle,
      drawerTitle,
      drawerContent,
      onDrawerClose = () => {},
      drawerWidth = 456,
      children,
    },
    ref,
  ) => {
    const [drawer, showDrawer] = useState(false);
    const { t } = useTranslation();
    const handleOpenDrawer = (status: boolean) => () => {
      showDrawer(status);
      if (!status) {
        onDrawerClose();
      }
    };
    useImperativeHandle(ref, () => ({
      showDrawer,
    }));
    return (
      <>
        <Card
          title={title}
          extra={
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleOpenDrawer(true)}
            >
              {buttonTitle || t('Добавить')}
            </Button>
          }
        >
          {children}
        </Card>
        {drawerContent && (
          <Drawer
            title={drawerTitle}
            placement="right"
            open={drawer}
            onClose={handleOpenDrawer(false)}
            width={drawerWidth}
          >
            {drawerContent}
          </Drawer>
        )}
      </>
    );
  },
);

export default Crud;
