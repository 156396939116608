import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  Pagination,
  PostResponse,
  PaginationResponse,
  UserResponse,
  UserCreate,
  UserMeResponse,
  LoginResponse,
  LoginRequest,
} from '@root/store/types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery,
  tagTypes: ['User'],
  endpoints: builder => ({
    getMe: builder.query<UserMeResponse, void>({
      query: () => '/user/profile',
      providesTags: ['User'],
    }),
    getUser: builder.query<
      PaginationResponse<UserResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) => `/user?page=${page}&limit=${limit}`,
      providesTags: ['User'],
    }),
    addUser: builder.mutation<PostResponse, UserCreate>({
      query: body => ({
        url: 'user',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<PostResponse, UserCreate & { id: number }>({
      query: body => ({
        url: `user/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: body => ({
        url: '/auth/login',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetUserQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useLoginMutation,
} = userApi;
