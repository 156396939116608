import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  ApiResponse,
  Pagination,
  PaginationResponse,
  PostResponse,
} from '@root/store/types';
import type { FinTask } from '@root/store/types/fin-task';

export const finTaskApi = createApi({
  reducerPath: 'finTaskApi',
  baseQuery,
  tagTypes: ['FinTask'],
  endpoints: builder => ({
    getFinTasks: builder.query<
      PaginationResponse<FinTask[]>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/fin-task?page=${page}&limit=${limit}`,
      providesTags: ['FinTask'],
    }),
    createFinTask: builder.mutation<ApiResponse<FinTask>, Partial<FinTask>>({
      query: body => ({
        url: '/fin-task',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FinTask'],
    }),
    deleteFinTask: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `/fin-task/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FinTask'],
    }),
  }),
});

export const {
  useGetFinTasksQuery,
  useCreateFinTaskMutation,
  useDeleteFinTaskMutation,
} = finTaskApi;
