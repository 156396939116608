import React, { useRef, useState } from 'react';
import {
  Form,
  Input,
  InputNumber,
  notification,
  Table,
  TablePaginationConfig,
} from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  Crud,
  CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { CardForm, type FormHandlers } from '@root/feature/forms';
import { toDataSource } from '@root/lib';
import { splitIntoChunks } from '@root/lib/helpers';
import {
  useDeleteCardMutation,
  useGetCardQuery,
  useUpdateCardMutation,
} from '@root/store/api/card';
import type { Card, CardResponseItem } from '@root/store/types';

const CardLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<number | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetCardQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const [deleteCard] = useDeleteCardMutation();
  const [update, { isLoading: isUpdating }] = useUpdateCardMutation();
  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  const handleEditRow = (row: Card) => () => {
    form.setFieldsValue({ ...row });
    setEditableRowId(row.id);
  };
  const handleDeleteRow = (row: Card) => () => {
    deleteCard(row.id).catch(() => {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось удалить запись'),
      });
    });
  };

  const isEditing = (id: number) => id === editableRowId;

  const columns: ColumnType<CardResponseItem>[] = [
    {
      title: t('Карта'),
      dataIndex: 'number',
      width: '20%',
      render: cardNumber => splitIntoChunks(cardNumber),
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'number',
        title: t('Карта'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Баланс'),
      dataIndex: 'balance',
      width: '15%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'balance',
        title: t('Баланс'),
        itemType: 'number',
        inputNode: (<InputNumber />) as React.JSX.Element,
      }),
    },
    {
      title: t('Аккаунт'),
      dataIndex: 'account',
      width: '20%',
      render: row => `${row.login} (${row.phone})`,
    },
    {
      title: t('Public ID'),
      dataIndex: 'public_id',
      width: '25%',
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={editableRowId !== undefined && editableRowId !== row.id}
            onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const handleSaveRow = async () => {
    try {
      const row = (await form.validateFields()) as Card;
      await update({ ...row, id: editableRowId as number });
      setEditableRowId(undefined);
    } catch (e) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось обновить запись'),
      });
    }
  };

  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };

  const rows = toDataSource<CardResponseItem>(data?.data || []);
  return (
    <>
      {contextHolder}
      <Crud
        title={t('Карты')}
        drawerTitle={t('Добавить карту')}
        ref={crudRef}
        drawerContent={<CardForm ref={formRef} onSubmit={handleSubmit} />}
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<CardResponseItem>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default CardLayout;
