import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import { useErrorNotification } from '@root/hooks/useErrorNotification';
import { useGetCompaniesQuery } from '@root/store/api/company';
import { useGetRoleQuery } from '@root/store/api/role';
import { useAddUserMutation } from '@root/store/api/user';
import type { UserCreate } from '@root/store/types';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const UserForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addUser, { isLoading, isError, isSuccess }] = useAddUserMutation();
    const { data, isLoading: isRoleLoading } = useGetRoleQuery({
      page: 1,
      limit: 50,
    });
    const { data: cData, isLoading: isCompanyLoading } = useGetCompaniesQuery({
      page: 1,
      limit: 50,
    });
    const [form] = Form.useForm();
    useErrorNotification(isSuccess, null, () => {
      onSubmit(true);
    });

    useErrorNotification(isError, t('Не удалось создать запись'), () => {
      onSubmit(false);
    });
    const handleSubmit = (values: UserCreate) => {
      const updatedValues: UserCreate = {
        ...values,
      };
      addUser(updatedValues as UserCreate);
      form.resetFields();
    };

    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    const roleData = data?.data || [];
    const companyData = cData?.data || [];
    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item label={t('Имя')} name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Email')}
          name="email"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Пароль')}
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t('Компания')}
          name="company_id"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Выберите компанию')}
            loading={isCompanyLoading}
            showSearch
            filterOption={(input, option) => {
              return (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {companyData.map((company, key) => (
              <Select.Option key={key} value={company.id}>
                {company.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('Роли')}
          name="role_ids"
          rules={[{ required: true, type: 'array' }]}
        >
          <Select
            mode="multiple"
            placeholder={t('Добавьте роли')}
            loading={isRoleLoading}
            filterOption={(input, option) => {
              return (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {roleData.map((role, key) => (
              <Select.Option key={key} value={role.role_id}>
                {role.role_id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default UserForm;
