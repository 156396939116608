import { useEffect } from 'react';
import { App } from 'antd';

export function useErrorNotification(
  isError: boolean,
  errorMessage: string | null,
  callback?: () => void,
) {
  const { message } = App.useApp();
  useEffect(() => {
    if (isError) {
      if (errorMessage) {
        message.error(errorMessage);
      }
      if (callback) {
        callback();
      }
    }
  }, [callback, errorMessage, isError, message]);
}
