import React, { useEffect, useState } from 'react';
import { Form, FormInstance, Input, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorNotification } from '@root/hooks/useErrorNotification';
import { useGetBanksQuery } from '@root/store/api/bank';
import { Bank } from '@root/store/types';

interface FormData {
  phone_number: string;
  amount: number | null;
  bank: string;
}

type Props = {
  onChange?: (_data: FormData) => void;
  form?: FormInstance<any>;
};

const PhoneTransferForm: React.FC<Props> = ({ onChange = () => {}, form }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    phone_number: '',
    bank: '',
    amount: null,
  });

  const { data, isLoading, isError } = useGetBanksQuery({
    page: 1,
    limit: 50,
  });

  useErrorNotification(isError, t('Не удалось загрузить список банков'));
  useEffect(() => {
    onChange(formData);
  }, [formData, onChange]);

  useEffect(
    () => () => {
      const initialData = {
        phone_number: '',
        bank: '',
        amount: null,
      };
      if (form) {
        form.setFieldsValue(initialData);
      }
      setFormData(initialData);
    },
    [form],
  );
  const handleChange =
    (name: keyof FormData) =>
    (event: React.ChangeEvent<HTMLInputElement> | number | string) => {
      const value =
        typeof event === 'number' || typeof event === 'string'
          ? event
          : event.target.value;
      setFormData({ ...formData, [name]: value });
    };

  const banks = (data?.data || []) as Bank[];

  return (
    <>
      <Form.Item
        name="phone_number"
        label={t('Номер телефона')}
        style={{ marginBottom: 10 }}
        rules={[{ required: true }]}
      >
        <Input
          value={formData.phone_number}
          onChange={e => handleChange('phone_number')(e)}
        />
      </Form.Item>
      <Form.Item
        name="bank"
        label={t('Банк')}
        rules={[{ required: true }]}
        style={{ marginBottom: 10 }}
      >
        <Select
          loading={isLoading}
          onChange={value => handleChange('bank')(value)}
        >
          {banks.map(bank => (
            <Select.Option key={bank.id} value={bank.short_name} щ>
              `{bank.short_name}: {bank.name}`
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="amount"
        label={t('Сумма')}
        rules={[{ required: true }]}
        style={{ marginBottom: 0 }}
      >
        <InputNumber
          value={formData.amount}
          onChange={value => handleChange('amount')(value || 0)}
        />
      </Form.Item>
    </>
  );
};

export default PhoneTransferForm;
