import React, { useEffect } from 'react';
import { CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, App } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useGenerateApiTokenMutation,
  useGetApiTokenQuery,
} from '@root/store/api/api-token';
import styles from './ApiKeyTab.module.scss';

const ApiKeyTab: React.FC = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { data: tokenData, isLoading: isTokenLoading } = useGetApiTokenQuery();
  const [generate, { isLoading: isGenerating, isSuccess }] =
    useGenerateApiTokenMutation();
  const isLoading = isTokenLoading || isGenerating;

  const handleGenerate = () => {
    generate();
  };

  useEffect(() => {
    if (isSuccess) {
      message.success(t('Ключ успешно сгенерирован'));
    }
  }, [isSuccess, message, t]);

  async function copyToClipboard(text: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {}
  }

  const handleCopy = async () => {
    await copyToClipboard(tokenData?.token || '');
    message.info(t('Скопировано в буфер обмена'));
  };

  return (
    <div className={styles.container}>
      <Form.Item label={t('API-token')} style={{ width: '100%' }}>
        <Input
          readOnly
          style={{ width: '100%' }}
          value={tokenData?.token || ''}
        />
      </Form.Item>
      <Button icon={<CopyOutlined />} onClick={handleCopy} />
      <Button
        icon={<ReloadOutlined />}
        type="primary"
        loading={isLoading}
        onClick={handleGenerate}
      />
    </div>
  );
};

export default ApiKeyTab;
