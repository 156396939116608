import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import { useAddProxyMutation } from '@root/store/api/proxy';
import type { TwoFactorBridge } from '@root/store/types';
import { ProxyType } from '@root/store/types';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const ProxyForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addProxy, { isLoading }] = useAddProxyMutation();
    const [form] = Form.useForm();
    const handleSubmit = (values: Record<string, any>) => {
      addProxy(values as Omit<TwoFactorBridge, 'id'>)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
        });
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item label={t('Тип')} name="type" rules={[{ required: true }]}>
          <Select>
            {Object.keys(ProxyType).map(key => (
              <Select.Option key={key} value={key}>
                {t(key)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('Название')}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('Хост')} name="host" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Порт')}
          name="port"
          rules={[{ required: true, type: 'number' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label={t('Login')}
          name="login"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Password')}
          name="password"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default ProxyForm;
