import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import countryList from '@root/mock/countryList.json';

type Props = {
  submitTitle?: string;
  onSubmit?: (_values: Record<string, any>) => void;
  loading?: boolean;
};

const BankForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {}, loading }, ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const handleSubmit = (values: Record<string, any>) => {
      onSubmit(values);
      form.resetFields();
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
        initialValues={{
          country: 'RUS',
        }}
      >
        <Form.Item
          label={t('Название')}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Страна')}
          name="country"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Выберите страну')}
            filterOption={(input, option) => {
              return (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            showSearch
          >
            {countryList.map((country, key) => (
              <Select.Option key={key} value={country.code}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('Сокращенное название')} name="short_name">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default BankForm;
