import React from 'react';
import Cookies from 'js-cookie';
import { Navigate, Route, Routes } from 'react-router-dom';
import { APP_TOKEN_KEY } from '@root/constants/app';
import { AppLayout } from '@root/layout';
import { SignIn } from '@root/pages';
import routes from '@root/routes';

function App() {
  const token = Cookies.get(APP_TOKEN_KEY);
  const authorized = !!token;
  return (
    <AppLayout authorized={authorized}>
      <Routes>
        {authorized ? (
          <>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            {routes.map(route => (
              <Route
                key={route.link}
                path={route.link}
                element={route.element}
              />
            ))}
            <Route path="*" element={<Navigate to={'/404'} />} />
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<SignIn />} />
          </>
        )}
      </Routes>
    </AppLayout>
  );
}

export default App;
