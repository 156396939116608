import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  TwoFactorBridge as TwoFactorBridgeType,
  Pagination,
  PostResponse,
  PaginationResponse,
  TwoFactorBridgeResponse,
} from '@root/store/types';

export const proxyApi = createApi({
  reducerPath: 'proxyApi',
  baseQuery,
  tagTypes: ['Proxy'],
  endpoints: builder => ({
    getProxy: builder.query<
      PaginationResponse<TwoFactorBridgeResponse>,
      Partial<Pagination & { unlinked: boolean }>
    >({
      query: ({ page = 1, limit = 10, unlinked = false }) => {
        const res = `/two-factoring-bridge?page=${page}&limit=${limit}`;
        if (unlinked) return res + '&unlinked=true';
        return res;
      },
      providesTags: ['Proxy'],
    }),
    addProxy: builder.mutation<PostResponse, Omit<TwoFactorBridgeType, 'id'>>({
      query: body => ({
        url: 'two-factoring-bridge',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Proxy'],
    }),
    updateProxy: builder.mutation<PostResponse, TwoFactorBridgeType>({
      query: body => ({
        url: `two-factoring-bridge/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Proxy'],
    }),
    deleteProxy: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `two-factoring-bridge/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Proxy'],
    }),
  }),
});

export const {
  useGetProxyQuery,
  useAddProxyMutation,
  useUpdateProxyMutation,
  useDeleteProxyMutation,
} = proxyApi;
