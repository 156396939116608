import React, { useMemo } from 'react';
import { Layout, Menu, type MenuProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useGetMeQuery } from '@root/store/api/user';
import { navRoutes } from '@root/routes';
import { appSelector, type AppSliceState } from '@root/store/slices/appSlice';
import { useActions, useTypedSelector } from '@root/store/store';
import styles from './SideBar.module.scss';

const { Sider } = Layout;

const SideBar: React.FC = () => {
  const { sideBarCollapsed } = useTypedSelector<AppSliceState>(appSelector);
  const { setSideBarCollapsed } = useActions();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getCurrentRoute = () => {
    if (pathname === '/') {
      return navRoutes.find(route => route.link === '/');
    }
    return navRoutes.find(route => {
      if (route.link === '/') return false;
      const regex = new RegExp(`^${route.link}(?:/|$)`);
      return regex.test(pathname);
    });
  };
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  // const { data: userData } = useGetMeQuery();
  const currentRoute = getCurrentRoute();
  const handleMenuClick = ({ key }: { key: string }) => {
    navigate(key);
  };

  const items: MenuProps['items'] = useMemo(
    () =>
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
      // const permissions = (userData?.permissions || []).map(
      //   permission => permission.name,
      // );
      navRoutes
        .filter(route => {
          if (!route.permissions || route.permissions.length === 0) return true;
          return route.permissions.some(
            () => true,
            // permissions.includes(permission),
          );
        })
        .map(route => ({
          key: route.link,
          icon: React.createElement(route.icon as unknown as string),
          label: route.label,
        })),
    [],
  );
  // }, [userData]);

  const handleCollapse = (collapsed: boolean) => {
    setSideBarCollapsed({
      collapsed,
    });
  };

  return (
    <div className={styles.sideBar}>
      <Sider
        collapsible
        collapsed={sideBarCollapsed}
        onCollapse={handleCollapse}
        width={256}
        theme="light"
        style={{
          height: '100%',
          paddingTop: 32,
          boxShadow: '0 8px 24px rgba(166, 171, 189, 0.2)',
        }}
      >
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[currentRoute?.link || navRoutes[0]?.link]}
          items={items}
          onClick={handleMenuClick}
        />
      </Sider>
    </div>
  );
};

export default SideBar;
