import React, { useRef, useState } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  notification,
  Table,
  TablePaginationConfig,
} from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  Crud,
  CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { DeviceForm, type FormHandlers } from '@root/feature/forms';
import { QrCodeModal } from '@root/layout/DeviceLayout/parts';
import { toDataSource } from '@root/lib';
import {
  useDeleteDeviceMutation,
  useGetDevicesQuery,
  useUpdateDeviceMutation,
} from '@root/store/api/device';
import type { Device } from '@root/store/types';

const DeviceLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [deviceId, setDeviceId] = useState<number | undefined>(undefined);
  const [editableRowId, setEditableRowId] = useState<number | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetDevicesQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const [deleteDevice] = useDeleteDeviceMutation();
  const [update, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  const handleEditRow = (row: Device) => () => {
    form.setFieldsValue({ ...row });
    setEditableRowId(row.id);
  };
  const handleDeleteRow = (row: Device) => () => {
    deleteDevice(row.id).catch(() => {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось удалить запись'),
      });
    });
  };
  const isEditing = (id: number) => id === editableRowId;
  const handleSetDeviceId = (id: number | undefined) => () => {
    setDeviceId(id);
  };

  console.log(deviceId);

  const columns: ColumnType<Device>[] = [
    {
      title: t('Название'),
      dataIndex: 'name',
      width: '20%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'name',
        title: t('Название'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Описание'),
      dataIndex: 'info',
      width: '20%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'info',
        title: t('Описание'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Secret ID'),
      dataIndex: 'secret',
      width: '20%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'secret',
        title: t('Secret ID'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Public ID'),
      dataIndex: 'public_id',
      width: '20%',
    },
    {
      title: t('QR'),
      dataIndex: 'id',
      width: '10%',
      render: id => (
        <Button
          icon={<QrcodeOutlined onClick={handleSetDeviceId(id)} />}
          type="primary"
          size="small"
        />
      ),
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={editableRowId !== undefined && editableRowId !== row.id}
            onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const handleSaveRow = async () => {
    try {
      const row = (await form.validateFields()) as Device;
      await update({ ...row, id: editableRowId as number });
      setEditableRowId(undefined);
    } catch (e) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось обновить запись'),
      });
    }
  };

  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };

  const rows = toDataSource<Device>(data?.data || []);
  return (
    <>
      {contextHolder}
      <Crud
        title={t('Устройства')}
        drawerTitle={t('Добавить устройство')}
        ref={crudRef}
        drawerContent={<DeviceForm ref={formRef} onSubmit={handleSubmit} />}
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<Device>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
      <QrCodeModal
        open={!!deviceId}
        onClose={handleSetDeviceId(undefined)}
        deviceId={deviceId as number}
      />
    </>
  );
};

export default DeviceLayout;
