import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  Pagination,
  PostResponse,
  PaginationResponse,
  BotSettingsResponse,
  BotSettingsCreate,
} from '@root/store/types';

export const botSettingsApi = createApi({
  reducerPath: 'botSettingsApi',
  baseQuery,
  tagTypes: ['Bot-settings'],
  endpoints: builder => ({
    getBotSettings: builder.query<
      PaginationResponse<BotSettingsResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/bot-settings?page=${page}&limit=${limit}`,
      providesTags: ['Bot-settings'],
    }),
    addBotSettings: builder.mutation<PostResponse, BotSettingsCreate>({
      query: body => ({
        url: 'bot-settings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Bot-settings'],
    }),
    updateBotSettings: builder.mutation<
      PostResponse,
      BotSettingsCreate & { id: number }
    >({
      query: body => ({
        url: `bot-settings/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Bot-settings'],
    }),
    deleteBotSettings: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `bot-settings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Bot-settings'],
    }),
  }),
});

export const {
  useGetBotSettingsQuery,
  useAddBotSettingsMutation,
  useUpdateBotSettingsMutation,
  useDeleteBotSettingsMutation,
} = botSettingsApi;
