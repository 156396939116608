import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './TableActions.module.scss';

type Props = {
  disabled?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  deleteTitle?: string;
};

const TableActions: React.FC<Props> = ({
  disabled,
  onEdit,
  onDelete,
  deleteTitle,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleConfirm = () => {
    setOpen(false);
    onDelete && onDelete();
  };
  const openConfirm = (status: boolean) => () => {
    setOpen(status);
  };
  return (
    <div className={styles.container}>
      {onEdit && (
        <Button
          type="primary"
          icon={<EditOutlined />}
          disabled={disabled}
          size="small"
          onClick={onEdit}
        />
      )}
      {onDelete && (
        <Popconfirm
          title={t(deleteTitle || t('Удалить?'))}
          open={open}
          onConfirm={handleConfirm}
          onCancel={openConfirm(false)}
        >
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            disabled={disabled}
            size="small"
            danger
            onClick={openConfirm(true)}
          />
        </Popconfirm>
      )}
    </div>
  );
};

export default TableActions;
