import React, { useState } from 'react';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './TableEditActions.module.scss';

type Props = {
  disabled?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  cancelTitle?: string;
  loading?: boolean;
};

const TableEditActions: React.FC<Props> = ({
  disabled,
  onSave,
  onCancel,
  cancelTitle,
  loading,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleConfirm = () => {
    setOpen(false);
    onCancel && onCancel();
  };
  const openConfirm = (status: boolean) => () => {
    setOpen(status);
  };
  return (
    <div className={styles.container}>
      {onSave && (
        <Button
          type="primary"
          icon={<SaveOutlined />}
          disabled={disabled}
          size="small"
          onClick={onSave}
          loading={loading}
        />
      )}
      {onCancel && (
        <Popconfirm
          title={t(cancelTitle || t('Отменить редактирование?'))}
          open={open}
          onConfirm={handleConfirm}
          onCancel={openConfirm(false)}
        >
          <Button
            type="primary"
            icon={<CloseOutlined />}
            disabled={disabled}
            size="small"
            danger
            onClick={openConfirm(true)}
          />
        </Popconfirm>
      )}
    </div>
  );
};

export default TableEditActions;
