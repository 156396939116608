import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  CompanyResponse,
  Pagination,
  PostResponse,
  PaginationResponse,
  CompanyCreate,
} from '@root/store/types';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery,
  tagTypes: ['Company'],
  endpoints: builder => ({
    getCompanies: builder.query<
      PaginationResponse<CompanyResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/company?page=${page}&limit=${limit}`,
      providesTags: ['Company'],
    }),
    addCompany: builder.mutation<PostResponse, CompanyCreate>({
      query: body => ({
        url: 'company',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Company'],
    }),
    updateCompany: builder.mutation<
      PostResponse,
      CompanyCreate & { id: number }
    >({
      query: body => ({
        url: `company/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Company'],
    }),
    deleteCompany: builder.mutation<PostResponse, number>({
      query: id => ({
        url: `company/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company'],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = companyApi;
