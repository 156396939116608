import React from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';

const NotFoundLayout = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('Страница не найдена')}
      extra={<Button type="primary">{t('На главную')}</Button>}
    />
  );
};

export default NotFoundLayout;
