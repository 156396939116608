import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Space,
  Table,
  TablePaginationConfig,
} from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  CopyButton,
  Crud,
  CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { type FormHandlers, ProxyForm } from '@root/feature/forms';
import { toDataSource } from '@root/lib';
import { makeProxyString } from '@root/lib/helpers';
import {
  useDeleteProxyMutation,
  useGetProxyQuery,
  useUpdateProxyMutation,
} from '@root/store/api/proxy';
import { Account, ProxyType, TwoFactorBridge } from '@root/store/types';

const ProxyLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<number | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetProxyQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const [deleteProxy, { isError: isDeleteError }] = useDeleteProxyMutation();
  const [update, { isLoading: isUpdating }] = useUpdateProxyMutation();
  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  useEffect(() => {
    if (isDeleteError) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось удалить запись'),
      });
    }
  }, [api, isDeleteError, t]);
  const handleEditRow = (row: TwoFactorBridge) => () => {
    form.setFieldsValue({ ...row });
    setEditableRowId(row.id);
  };
  const handleDeleteRow = (row: TwoFactorBridge) => () => {
    deleteProxy(row.id);
  };
  const isEditing = (id: number) => id === editableRowId;
  const columns: ColumnType<TwoFactorBridge>[] = [
    {
      title: t('Название'),
      dataIndex: 'name',
      width: '15%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'name',
        title: t('Название'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Тип'),
      dataIndex: 'type',
      width: '10%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'type',
        title: t('Тип'),
        inputNode: (
          <Select>
            {Object.keys(ProxyType).map(key => (
              <Select.Option key={key} value={key}>
                {t(key)}
              </Select.Option>
            ))}
          </Select>
        ) as React.JSX.Element,
      }),
    },
    {
      title: t('Хост'),
      dataIndex: 'host',
      width: '10%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'host',
        title: t('Хост'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Порт'),
      dataIndex: 'port',
      width: '10%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'port',
        itemType: 'number',
        title: t('Порт'),
        inputNode: (<InputNumber />) as React.JSX.Element,
      }),
    },
    {
      title: t('Логин'),
      dataIndex: 'login',
      width: '10%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'login',
        title: t('Login'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Пароль'),
      dataIndex: 'password',
      width: '10%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.id),
        isRequired: true,
        dataIndex: 'password',
        title: t('Password'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Аккаунт'),
      dataIndex: 'accounts',
      width: '15%',
      ellipsis: true,
      render: (accounts: Account[]) =>
        !!accounts.length ? accounts[0].login : null,
    },
    {
      title: t('Адрес'),
      width: '15%',
      ellipsis: true,
      render: row => (
        <Space size="small">
          <CopyButton textToCopy={makeProxyString(row)} />
          {makeProxyString(row)}
        </Space>
      ),
    },
    {
      width: '10%',
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={editableRowId !== undefined && editableRowId !== row.id}
            onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const handleSaveRow = async () => {
    try {
      const row = (await form.validateFields()) as TwoFactorBridge;
      await update({
        ...row,
        port: Number(row.port),
        id: editableRowId as number,
      });
      setEditableRowId(undefined);
    } catch (e) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось обновить запись'),
      });
    }
  };
  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };
  const rows = toDataSource<TwoFactorBridge>(data?.data || []);
  return (
    <>
      {contextHolder}
      <Crud
        title={t('Прокси')}
        drawerTitle={t('Добавить прокси')}
        ref={crudRef}
        drawerContent={<ProxyForm ref={formRef} onSubmit={handleSubmit} />}
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<TwoFactorBridge>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default ProxyLayout;
