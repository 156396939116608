import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@root/store/baseQuery';
import type {
  NotificationResponse,
  Pagination,
  PaginationResponse,
} from '@root/store/types';

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: baseQuery,
  tagTypes: ['Notification'],
  endpoints: builder => ({
    getNotifications: builder.query<
      PaginationResponse<NotificationResponse>,
      Partial<Pagination>
    >({
      query: ({ page = 1, limit = 10 }) =>
        `/notification?page=${page}&limit=${limit}`,
      providesTags: ['Notification'],
    }),
    readNotification: builder.mutation({
      query: id => ({
        url: `/notification/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Notification'],
    }),
  }),
});

export const { useGetNotificationsQuery, useReadNotificationMutation } =
  notificationApi;
