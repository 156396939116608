import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import { stringToIdentifier } from '@root/lib/helpers';
import { useGetPermissionsQuery } from '@root/store/api/permission';
import { useAddRoleMutation } from '@root/store/api/role';
import type { Role } from '@root/store/types';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const RoleForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addRole, { isLoading }] = useAddRoleMutation();
    const { data, isLoading: isPermissionsLoading } = useGetPermissionsQuery({
      page: 1,
      limit: 50,
    });
    const [form] = Form.useForm();
    const handleSubmit = (values: Record<string, any>) => {
      const updatedValues: Role = {
        ...values,
        role_id: stringToIdentifier(values.name, true),
      };
      addRole(updatedValues as Role & { permission_ids: string[] })
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
        });
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    const permissionsData = data?.data || [];
    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          label={t('Название')}
          name="role_id"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Доступы')}
          name="permission_ids"
          rules={[{ required: true, type: 'array' }]}
        >
          <Select
            mode="multiple"
            placeholder={t('Добавьте доступы к роли')}
            loading={isPermissionsLoading}
            filterOption={(input, option) => {
              return (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {permissionsData.map((permission, key) => (
              <Select.Option key={key} value={permission.permission_id}>
                {permission.permission_id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default RoleForm;
