import React, { useRef, useState } from 'react';
import {
  Form,
  Input,
  notification,
  Table,
  Space,
  type TablePaginationConfig,
  Tag,
  Select,
} from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  Crud,
  type CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { type FormHandlers, UserForm } from '@root/feature/forms';
import { useErrorNotification } from '@root/hooks/useErrorNotification';
import { toDataSource } from '@root/lib';
import { useGetRoleQuery } from '@root/store/api/role';
import {
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from '@root/store/api/user';
import type { Role, User, UserCreate } from '@root/store/types';

const UserLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<number | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetUserQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const { data: roleData, isLoading: isRoleLoading } = useGetRoleQuery({
    page: 1,
    limit: 50,
  });
  const [deleteUser, { isError: isDeleteError }] = useDeleteUserMutation();
  const [update, { isLoading: isUpdating, isError: isUpdateError }] =
    useUpdateUserMutation();

  useErrorNotification(isDeleteError, t('Не удалось удалить запись'));
  useErrorNotification(isUpdateError, t('Не удалось обновить запись'));

  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  const totalRecords = data?.total || 0;
  const handleEditRow = (row: User) => () => {
    form.setFieldsValue({
      ...row,
      role_ids: row.roles!.map(({ role_id }) => role_id),
    });
    setEditableRowId(row.id);
  };

  const handleDeleteRow = (row: User) => () => {
    deleteUser(row.id);
  };
  const isEditing = (id: number) => id === editableRowId;
  const columns: ColumnType<User>[] = [
    {
      title: t('ID'),
      dataIndex: 'id',
      width: '5%',
      sorter: {
        compare: (a, b) => a.id - b.id,
      },
    },
    {
      title: t('Имя'),
      dataIndex: 'name',
      width: '15%',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      onCell: record => ({
        record,
        editing: isEditing(record.id),
        isRequired: true,
        dataIndex: 'name',
        title: t('Имя'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      width: '15%',
      onCell: record => ({
        record,
        editing: isEditing(record.id),
        isRequired: true,
        dataIndex: 'email',
        title: t('Email'),
        itemType: 'email',
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    // {
    //   title: t('Public ID'),
    //   dataIndex: 'public_id',
    //   width: '20%',
    // },
    {
      title: t('Комания'),
      dataIndex: 'company',
      width: '15%',
      render: (record: { name: string }) => record.name,
    },
    {
      title: t('Webhook URL'),
      dataIndex: 'webhook_url',
      width: '15%',
      onCell: record => ({
        record,
        editing: isEditing(record.id),
        itemType: 'url',
        dataIndex: 'webhook_url',
        title: t('Webhook URL'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      title: t('Роли'),
      dataIndex: 'roles',
      width: '20%',
      render: (record: Role[]) => (
        <Space size={[0, 8]} wrap>
          {record.map((role, key) => (
            <Tag key={key} color="blue">
              {role.role_id}
            </Tag>
          ))}
        </Space>
      ),
      onCell: record => ({
        record,
        editing: isEditing(record.id),
        isRequired: true,
        dataIndex: 'role_ids',
        itemType: 'array',
        inputNode: (
          <Select
            mode="multiple"
            placeholder={t('Добавьте роли пользователя')}
            loading={isRoleLoading}
            filterOption={(input, option) =>
              (String(option?.children) || '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {(roleData?.data || []).map((role, key) => (
              <Select.Option key={key} value={role.role_id}>
                {role.role_id}
              </Select.Option>
            ))}
          </Select>
        ) as React.JSX.Element,
      }),
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={editableRowId !== undefined && editableRowId !== row.id}
            onEdit={handleEditRow(row)}
            onDelete={totalRecords > 1 ? handleDeleteRow(row) : undefined}
          />
        ),
    },
  ];

  const handleSaveRow = async () => {
    const row = (await form.validateFields()) as Omit<
      UserCreate,
      'password' | 'company_id'
    > & { id: number };
    update({
      ...row,
      id: editableRowId as number,
    });
    setEditableRowId(undefined);
  };

  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };

  const rows = toDataSource<User>(data?.data || []);
  return (
    <>
      {contextHolder}
      <Crud
        title={t('Пользователи')}
        drawerTitle={t('Добавить пользователя')}
        ref={crudRef}
        drawerContent={<UserForm ref={formRef} onSubmit={handleSubmit} />}
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<User>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default UserLayout;
