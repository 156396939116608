import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import { useAddCompanyMutation } from '@root/store/api/company';
import type { Company } from '@root/store/types';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const CompanyForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addCompany, { isLoading }] = useAddCompanyMutation();
    const [form] = Form.useForm();
    const handleSubmit = (values: Record<string, any>) => {
      addCompany(values as Omit<Company, 'id'>)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
        });
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          label={t('Название')}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('Информация')}
          name="description"
          rules={[{ required: false }]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default CompanyForm;
