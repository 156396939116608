import React, { PropsWithChildren } from 'react';
import i18n_Initialize from '@root/i18n/i18n';

i18n_Initialize();

const IntlProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

export default IntlProvider;
