import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import type { FormHandlers } from '@root/feature/forms';
import { stringToIdentifier } from '@root/lib/helpers';
import { useAddPermissionMutation } from '@root/store/api/permission';
import type { Permission } from '@root/store/types';

type Props = {
  submitTitle?: string;
  onSubmit?: (_success: boolean) => void;
  loading?: boolean;
};

const PermissionForm = forwardRef<FormHandlers, Props>(
  ({ submitTitle, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation();
    const [addPermission, { isLoading }] = useAddPermissionMutation();
    const [form] = Form.useForm();
    const handleSubmit = (values: Record<string, any>) => {
      const updatedValues: Permission = {
        ...values,
        permission_id: stringToIdentifier(values.permission_id),
      };
      addPermission(updatedValues as Permission)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => {
          onSubmit(false);
        })
        .finally(() => {
          form.resetFields();
        });
    };
    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
    }));
    return (
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          label={t('Название')}
          name="permission_id"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('Описание')} name="description">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t(submitTitle || t('Добавить'))}
          </Button>
        </Form.Item>
      </Form>
    );
  },
);

export default PermissionForm;
