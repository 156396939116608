import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { appConfig } from '@root/config';
import { APP_TOKEN_KEY } from '@root/constants/app';

export const baseQueryF = fetchBaseQuery({
  baseUrl: `${appConfig.apiUrl}/api/`,
  prepareHeaders: async headers => {
    const token = Cookies.get(APP_TOKEN_KEY);
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryF(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    Cookies.remove(APP_TOKEN_KEY);
    window.location.href = '/login';
  }
  return result;
};
