import React, { useRef, useState } from 'react';
import { Form, Input, notification, Table, TablePaginationConfig } from 'antd';
import type { ColumnType } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import {
  Crud,
  CrudHandlers,
  EditableCell,
  TableActions,
  TableEditActions,
} from '@root/components';
import { type FormHandlers, PermissionForm } from '@root/feature/forms';
import { toDataSource } from '@root/lib';
import { stringToIdentifier } from '@root/lib/helpers';
import {
  useDeletePermissionMutation,
  useGetPermissionsQuery,
  useUpdatePermissionMutation,
} from '@root/store/api/permission';
import type { Permission } from '@root/store/types';

const PermissionLayout: React.FC = () => {
  const [form] = Form.useForm();
  const [editableRowId, setEditableRowId] = useState<string | undefined>(
    undefined,
  );
  const [api, contextHolder] = notification.useNotification();
  const crudRef = useRef<CrudHandlers>(null);
  const formRef = useRef<FormHandlers>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const { data, isLoading } = useGetPermissionsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const [deletePermission] = useDeletePermissionMutation();
  const [update, { isLoading: isUpdating }] = useUpdatePermissionMutation();
  const handleSubmit = (status: boolean) => {
    crudRef.current?.showDrawer(false);
    if (!status) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось создать запись'),
      });
    }
  };

  const handleEditRow = (row: Permission) => () => {
    form.setFieldsValue({ ...row });
    setEditableRowId(row.permission_id);
  };
  const handleDeleteRow = (row: Permission) => () => {
    deletePermission(row.permission_id).catch(() => {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось удалить запись'),
      });
    });
  };
  const isEditing = (id: string) => id === editableRowId;
  const columns: ColumnType<Permission>[] = [
    {
      title: t('Название'),
      dataIndex: 'permission_id',
      width: '40%',
    },
    {
      title: t('Описание'),
      dataIndex: 'description',
      width: '40%',
      onCell: row => ({
        record: row,
        editing: isEditing(row.permission_id),
        isRequired: true,
        dataIndex: 'description',
        title: t('Описание'),
        inputNode: (<Input />) as React.JSX.Element,
      }),
    },
    {
      render: row =>
        row.id === editableRowId ? (
          <TableEditActions
            onSave={handleSaveRow}
            onCancel={() => setEditableRowId(undefined)}
            loading={isUpdating}
          />
        ) : (
          <TableActions
            disabled={
              editableRowId !== undefined &&
              editableRowId !== row.id &&
              !row.read_only
            }
            onEdit={handleEditRow(row)}
            onDelete={handleDeleteRow(row)}
          />
        ),
    },
  ];

  const handleSaveRow = async () => {
    try {
      const row = (await form.validateFields()) as Permission;
      await update({
        ...row,
        permission_id: stringToIdentifier(row.permission_id),
      });
      setEditableRowId(undefined);
    } catch (e) {
      api.error({
        message: t('Ошибка'),
        description: t('Не удалось обновить запись'),
      });
    }
  };
  const handleTableChange = (values: TablePaginationConfig) => {
    setPagination(values as typeof pagination);
  };
  const rows = toDataSource<Permission>(data?.data || []);
  return (
    <>
      {contextHolder}
      <Crud
        title={t('Разрешения')}
        drawerTitle={t('Добавить разрешение')}
        ref={crudRef}
        drawerContent={<PermissionForm ref={formRef} onSubmit={handleSubmit} />}
        onDrawerClose={formRef.current?.resetFields}
      >
        <Form form={form} component={false}>
          <Table<Permission>
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={rows}
            loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              total: data?.total || 1,
            }}
          />
        </Form>
      </Crud>
    </>
  );
};

export default PermissionLayout;
