import React from 'react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Layout, MenuProps, Space } from 'antd';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APP_TOKEN_KEY } from '@root/constants/app';
import { useGetMeQuery } from '@root/store/api/user';
import styles from './AppHeader.module.scss';

const { Header } = Layout;

const AppHeader: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData, isLoading } = useGetMeQuery();

  const navigate = useNavigate();
  console.log(userData, isLoading);
  const handleLogout = () => {
    Cookies.remove(APP_TOKEN_KEY);
    window.location.href = '/';
  };

  const userName = isLoading
    ? `${t('Loading')}...`
    : userData?.name || userData?.email;

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: t('Профиль'),
      icon: <UserOutlined />,
      onClick: () => navigate('/profile'),
    },
    {
      key: 'logout',
      label: t('Выйти'),
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];

  return (
    <Header className={styles.header}>
      <span>{t('AutoBank')}</span>
      <Dropdown
        menu={{
          items,
        }}
      >
        <Space style={{ color: 'white', cursor: 'pointer' }}>
          <UserOutlined />
          {userName}
        </Space>
      </Dropdown>
    </Header>
  );
};

export default AppHeader;
